// Pagination

.pagination {
  overflow: hidden; // clearfix
  margin: 0 -1.5rem 1rem;
  color: #ccc;
  text-align: center;
}

// Pagination items can be `span`s or `a`s
.pagination-item {
  display: block;
  padding: 1rem;
  border: solid #eee;
  border-width: 1px 0;
  
@media (prefers-color-scheme: dark) {
  border: solid #111111;
}

  &:first-child {
    margin-bottom: -1px;
  }
}

// Only provide a hover state for linked pagination items
a.pagination-item:hover {
  background-color: #eee;
  
@media (prefers-color-scheme: dark) {
  background-color: #1e1e1e;
  }
}

@media (min-width: 30em) {
  .pagination {
    margin: 3rem 0;
  }

  .pagination-item {
    float: left;
    width: 50%;
    border-width: 1px;

    &:first-child {
      margin-bottom: 0;
      border-top-left-radius:    4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      margin-left: -1px;
      border-top-right-radius:    4px;
      border-bottom-right-radius: 4px;
    }
  }
}
