// Buttons

.added {
    background: #6a28e8;
    color: #fff;
    text-align: center;
	font-variant: small-caps;
	font-weight: bold;
    padding: 1px 8px 3px;
    margin: 0 3px 3px 0;
    border-radius: 6px;
}

.improved {
    background: #0c83e1;
    color: #fff;
    text-align: center;
	font-variant: small-caps;
	font-weight: bold;
    padding: 1px 8px 3px;
    margin: 0 3px 3px 0;
    border-radius: 6px;
}

.fixed {
    background: #00d486;
    color: #fff;
    text-align: center;
	font-variant: small-caps;
	font-weight: bold;
    padding: 1px 8px 3px;
    margin: 0 3px 3px 0;
    border-radius: 6px;
}

.soon {
    background: #ff6600;
    color: #fff;
    text-align: center;
	font-variant: small-caps;
	font-weight: bold;
    padding: 1px 8px 3px;
    margin: 0 3px 3px 0;
    border-radius: 6px;
}

.removed {
    background: #ff0000;
    color: #fff;
    text-align: center;
	font-variant: small-caps;
	font-weight: bold;
    padding: 1px 8px 3px;
    margin: 0 3px 3px 0;
    border-radius: 6px;
}
