// Site structure

.container {
  min-width: 250px;
  max-width: 50rem;
  padding-left:  1.5rem;
  padding-right: 1.5rem;
  margin-left:  auto;
  margin-right: auto;
}

footer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 60%;
}
