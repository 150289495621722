// Color scheme for light and dark modes.

$light: #ff0066;
$dark: #1ba77e;

// Highlighting color for code block borders and language name.

$code-light: #ff0066;
$code-dark: #d0f6db;

$message-green: #62bb47;
$message-yellow: #fcb827;
$message-orange: #f6821f;
$message-red: #e03940;
$message-purple: #953c96;
$message-blue: #009ddc;

$root-font-family: "proxima-nova-n7", "proxima-nova-n6", "proxima-nova", sans-serif;
$code-font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$root-font-size: 16px;
$root-line-height: 1.3;//1.5;

$body-color: rgba(0,0,0,.8);
$body-bg: rgba(0,0,0,.03);
$body-color-dark: hsla(0,0%,100%,.8);
$body-bg-dark: rgba(0,0,0,.9);

$large-breakpoint: 38em;
$large-font-size: 20px;

// Midnight color scheme

$midnight-text-color: rgba(0,0,0,.8);
$midnight-text-color-dark: hsla(0,0%,100%,.9);
$midnight-body-bg: #FFFFFF;
$midnight-body-bg-dark: #000000;
$midnight-weak-accent-light: rgb(131, 62, 165);
$midnight-weak-accent-dark: $orange-dark;//rgba(10,132,255,1.0);
$midnight-accent-light: rgba(191,90,242,1.0);
$midnight-accent-dark: $orange-light;//rgba(10,132,255,1.0);
$midnight-strong-accent-light: rgba(191,90,242,1.0);
$midnight-strong-accent-dark: $orange-lighter;//rgba(10,132,255,1.0);
$midnight-code-light: rgba(191,90,242,1.0);
$midnight-code-dark: rgba(10,132,255,1.0);
$midnight-header-light: #838383;
$midnight-header-dark: #C0C0C0;
