// Masthead
//
// The style of the site's title and optional secondary description.

.masthead {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 10px;
  color: $midnight-text-color;
  position: relative;
  text-align: center;

  @media (prefers-color-scheme: dark) {
    color: $midnight-text-color-dark;
  }

  a {
    color: white;
    font-size: 1.4rem;
    font-variant: normal;
    font-stretch: normal;
    font-weight: 800;
  }

  small {
    display: none;
    font-size: 50%;
    font-weight: 400;
    opacity: 0.5;
    line-height: normal;
  }

  @media only screen and (min-width: 480px) {
    a {
      font-size: 2rem;
    }

    small {
      display: inherit;
      font-size: 65%;
    }
  }

  @media only screen and (min-width: 900px) {
    a {
      font-size: 3rem;
    }

    small {
      display: inherit;
      font-size: 75%;
    }
  }
}

.masthead-title-main {
  font-size: 120px;
  font-weight: 800;
}

.navigation {
  display: block;
  font-size: 100%;
  font-weight: 700;
  color: $midnight-text-color;
  border-bottom: 3px solid white;
  padding-bottom: 8px;

  @media (prefers-color-scheme: dark) {
    color: $midnight-text-color-dark;
  }

  ul#navigation-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;
      list-style: none;
      padding-left: 0;
      padding-right: 12px;
      margin: 0;

      color: $midnight-text-color;
      @media (prefers-color-scheme: dark) {
        color: $midnight-text-color-dark;
      }

      a {
        color: $midnight-text-color;
        @media (prefers-color-scheme: dark) {
          color: $midnight-text-color-dark;
        }

        &:focus,
        &:hover {
          color: $midnight-strong-accent-light;
          @media (prefers-color-scheme: dark) {
            color: $midnight-strong-accent-dark;
          }
        }
      }

      &.active a {
        color: $midnight-accent-light;

        @media (prefers-color-scheme: dark) {
          color: $midnight-accent-dark;
        }
      }
    }
  }
}

/* Hide the link that should open and close the topnav on small screens */
.navigation .navigation-icon {
  display: none;
}

// hide the "subscribe" text when the display is large
.navigation ul#navigation-list li#subscribe a {
  p {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .navigation {
    text-align: left;
  }

  .navigation ul#navigation-list {
    display: inline;
  }

  .navigation ul#navigation-list li:not(.active) {
    display: none;
  }

  .navigation a.navigation-icon {
    float: right;
    display: block;
  }

  // show the "subscribe" text when the display is large
  .navigation ul#navigation-list li#subscribe a {
    p {
      display: inline;
    }
  }

  // The "responsive" class is added to the navigation with JavaScript when the user clicks on the icon.
  // This class makes the topnav look good on small screens (display the links vertically instead of horizontally)
  .navigation.responsive {
    position: relative;
  }

  .navigation.responsive a.navigation-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navigation.responsive ul#navigation-list li {
    float: none;
    display: block;
    text-align: left;
  }
}

.language-selector {
  margin: 5px 3px;
  float: right;

  color: #505050;
  font-size: smaller;
  font-style: italic;

  a {
    min-width: 10px;
    font-style: normal;
  }
}

@media screen and (max-width: 600px) {
  .language-selector {
    // TODO: this change to remove the span is terrible, can we improve it?
    span.language-selector-label {
      display: none;
    }
  }
}
