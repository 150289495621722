// Posts and pages

figure {
  margin-right: 0;
  margin-left: 0;
  margin-top: 1.5em;
  margin-bottom: 2em;

  & > :nth-last-child(2) {
    margin-bottom: 0;

    & > :last-child {
      margin-bottom: 0;
    }
  }
  figcaption {
    color: #878787;
    margin-top: 0.3em;
    font-size: 0.8em;
    text-align: center;
  }
  &.screenshot {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    figcaption {
      margin-top: -1em;
      margin-right: 2em;
      margin-left: 2em;
    }
  }
  &.attributed-quote {
    margin-left: 0;
    margin-right: 0;

    figcaption {
      margin-top: 0.8em;
      margin-left: 4em;

      p {
        display: inline;
      }
      &::first-letter {
        margin-left: -2.5ex;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  figure {
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media screen and (max-width: 500px) {
  figure.screenshot figcaption {
    margin-top: 0;
  }
}

// End of alterations for captions.

.page,
.post {
  margin-bottom: 1em;

  li + li {
    margin-top: 0.25rem;
  }
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: $midnight-text-color;

  @media (prefers-color-scheme: dark) {
    color: $midnight-text-color-dark;
  }
}

.page-title,
.post-title {
  margin-top: 0;
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  color: darken($midnight-text-color, 30%);
  font-size: 75%;

  @media (prefers-color-scheme: dark) {
    color: darken($midnight-text-color-dark, 30%);
  }
}

.permalink {
  display: block;
  font-size: 90%;
  margin-top: -0.9rem;
  margin-bottom: 1.5rem;
  color: $midnight-text-color;

  @media (prefers-color-scheme: dark) {
    color: $midnight-text-color-dark;
  }
}

.external-link {
  display: block;
  font-size: 90%;
  margin-top: -0.9rem;
  margin-bottom: 1.5rem;
  opacity: 0.5;
  color: $midnight-text-color;

  @media (prefers-color-scheme: dark) {
    color: $midnight-text-color-dark;
  }
}

.tag-list {
  padding-top: 0px;
  padding-bottom: 4px;
  padding-right: 8px;
  border-radius: 5px;
  color: $midnight-text-color;

  @media (prefers-color-scheme: dark) {
    color: $midnight-code-dark;
  }
}

// Related posts
.related {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;

  @media (prefers-color-scheme: dark) {
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  }
}

.PageNavigation {
  font-size: 18px;
  display: block;
  width: auto;
  overflow: hidden;
}

.PageNavigation a {
  display: inline;
  width: auto;
  float: left;
  margin: 1em 0;
}

.PageNavigation .next {
  float: right;
  width: auto;
  text-align: right;
}

.related-posts {
  padding-left: 0;
  list-style: none;

  h3 {
    margin-top: 0;
  }

  li {
    small {
      font-size: 75%;
      color: #999;
    }

    a:hover {
      color: $midnight-accent-light;
      text-decoration: none;

      @media (prefers-color-scheme: dark) {
        color: $midnight-accent-dark;
      }

      small {
        color: inherit;
      }
    }
  }
}
