// for the footer

@mixin relative-font-size($ratio) {
	font-size: #{$ratio}rem;
}

.footer .wrapper {
	div {
		display: inline-block;
	}
}
.orange {
	color: #f66a0a;
}

.grey {
	color: #828282;
}

.svg-icon {
	width: 16px;
	height: 16px;
	display: inline-block;
	fill: currentColor;
	//padding: 5px 3px 2px 5px;
	vertical-align: text-bottom;
}

.orange {
	color: #f66a0a;
}

.social-media-list {
	list-style: none;
	margin-left: 0;
	display: inline-flex;

	li {
		margin: 5px;
	}
}

.social-links {
	@include relative-font-size(0.9375);
	color: $brand-color;
}
